import React, { useState } from 'react'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'

import CancelIcon from '@mui/icons-material/Cancel';
import PersonIcon from '@mui/icons-material/Person';

const GroupingDialogBox = ({
    groupingState,
    onGroupBookings,
    onOrderSelectedBookingsPath,
    onAddBookingsToGroup,
    onRemoveBookingFromGroup,
    onReorderGroupBookings,
    openInGoogleMaps
}) =>{

    const [showReorderError, setShowReorderError] = useState(false);

    const SortableBooking = SortableElement(({value}) =>{
        const {booking, type, label, transitCapacity} = value;
        const date = new Date(booking.date).toLocaleDateString();    
        return(
            <tr className={
                (type == "location") ?
                    "border-t-2 border-gray-300 text-sm text-primary-1 bg-green-200"
                :
                    "border-t-2 border-gray-300 text-sm text-sm text-red-500 bg-red-200"
            }
            >
                <td className="py-1 px-2 text-xs">
                    <div className="bg-white font-bold p-1 rounded-lg">{label}</div>
                </td>
                <td className="py-1 px-2 text-xs">{date}</td>
                <td className="py-1 px-2 text-xs">{booking.timeslot}</td>
                <td className="py-1 px-2 text-xs">
                    <div className="flex flex-col p-2">
                        <label className="mr-2">Capacity:</label>
                        <div className="">{`l: ${transitCapacity?.leng}`}</div>
                        <div className="">{`w: ${ transitCapacity?.width}`}</div>
                        <div className="">{`h: ${transitCapacity?.height}`}</div>
                        <div className="">{`wt: ${transitCapacity?.weight}`}</div>
                    </div>
                </td>
                {
                    (booking.trip) &&
                    <td className="py-1 px-2 text-xs">
                        <button
                            onClick={()=>onRemoveBookingFromGroup(booking)}
                        >
                            <CancelIcon
                                color='#FFFFFF'
                            />
                        </button>
                    </td>
                }
            </tr>
        )
    });
    
    const SortableBookingsList = SortableContainer(({items}) => {
        return (
          <tbody>
            {
                items.map((point, index) => {
    
                    return(
                        <SortableBooking key={`path-${point.booking._id}-${point.type}-${point.order}`} index={index} value={point} />
                    )
                })
            }
          </tbody>
        );
    });
    

    const onSortEnd = ({oldIndex, newIndex}) =>{

        // validate sort here

        const { booking, type, order } = groupingState.selectedBookingsPath[oldIndex];
        let alternatePointType = (type == "location") ? "destination" : "location";
        let incorrectLeadingPoints = [];

        //point can moved relative to other location points but cannot be after detinations depending on order
        incorrectLeadingPoints = groupingState.selectedBookingsPath.filter((point, index)=>{

            if( booking._id == point.booking._id && point.type == alternatePointType)
            {
                if ((type == "location" && index <= newIndex && point.order > order) || (type == "destination" && index >= newIndex && point.order < order))
                {
                    return true

                }
            }
            return false
        });

        if ( incorrectLeadingPoints.length != 0)
        {
            setShowReorderError(true);
            setTimeout(()=>{
                setShowReorderError(false);
            },3000)
            return
        }

        onOrderSelectedBookingsPath({oldIndex, newIndex});
    }

    const getActionButton  = () =>{
        const { selectedBookingsPath, selectedBookings, selectedTripBookings, trips } = groupingState;
        
        if (selectedTripBookings.length != 0 && selectedBookings.length != 0)
        {
            return(
                <button
                    className='text-sm text-white bg-gray-500 py-2 px-3 w-full mt-2 rounded-lg'
                    onClick={()=> onAddBookingsToGroup()}
                >Add to Group</button>
            )
        }
        else if (selectedTripBookings.length != 0)
        {
            // check if trip path is the same as the selected bookings path
            const trip = trips.filter((trip)=> trip._id == selectedTripBookings[0].trip)[0]
            //find the trip                        
            const hasBeenReordered = trip.path.some((point, index)=> {

                if (point._id != selectedBookingsPath[index]._id)
                {
                    return true;
                }
                return false
            })

            if(hasBeenReordered)
            {
                return(
                    <button
                        className='text-sm text-white bg-gray-500 py-2 px-3 w-full mt-2 rounded-lg'
                        onClick={()=> onReorderGroupBookings()}
                    >Re-Order Trip</button>
                )
            }
            else{
                return(<></>)
            }

           
        }
        else
        {
            return(
                <button
                    className='text-sm text-white bg-gray-500 py-2 px-3 w-full mt-2 rounded-lg'
                    onClick={()=> onGroupBookings()}
                >Group</button>
            )
        }
        
    }

    return(
        <div className='absolute right-3 top-16 bg-white rounded-lg p-5 items-center justify-center text-sm w-90'>
            <div className='flex flex-row justify-between items-center border-b-2 border-gray-500 p-1'>
                <h1 className='text-gray-500 font-bold text-xs'>Selected Bookings</h1>
                <button
                    className='py-1 px-2 bg-gray-500 text-sm text-white rounded-lg'
                    onClick={()=>openInGoogleMaps(groupingState.selectedBookingsPath)}
                > View In Google Maps</button>

            </div>
            <table className='w-full'>
                <thead className='text-gray-500 font-bold text-left text-xs'>
                    <tr>
                        <th></th>
                        <th className="py-1 px-2">Date</th>
                        <th className="py-1 px-2">Time</th>
                        <th className="py-1 px-2">Weight</th>
                        <th className="py-1 px-2">Remove</th>

                    </tr>
                </thead>
                <SortableBookingsList 
                    //distance={1}
                    pressDelay = {56}
                    items={groupingState.selectedBookingsPath} 
                    onSortEnd={onSortEnd} 
                />
            </table>
            
            {
                getActionButton()
            }
            {
                showReorderError ?
                    <div className='p-5 text-sm text-yellow-500 items-center justify-center w-full bg-yellow-200 mt-2 rounded-lg'>
                        <p>Dropoff point for booking cannot be placed before the pickup location.</p>
                    </div>
                :
                    <div className='text-sm text-gray-500 items-center justify-center w-full mt-2'>
                        Drag and drop to re-order way points.
                    </div>
            }
        </div>
    )
}

export default GroupingDialogBox;