import * as TripPermissions from '../modules/TripPermissions'

import React,{useState} from 'react'

import CancelIcon from '@mui/icons-material/Cancel';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import {Link} from 'react-router-dom'
import { COLOR_CODES } from '../../../util/general.util';

const TripComponent = ({
    trip,
    role
}) =>{

    const [collapsed, setCollapsed] = useState(false);
    const date = new Date(trip.date).toLocaleDateString()

        return (
            <div className="flex flex-col border-b-2 text-xs py-2 bg-white" key={trip._id}>
                <div className={`flex w-full items-start px-2 ${ (collapsed == true) ? "rounded-t-lg bg-gray-300" : "rounded-lg" }`}>
                    <div className="w-full grid grid-cols-3 lg:grid-cols-12  p-2 gap-4 lg:pr-16">
                        <div className="flex flex-col col-span-1 lg:col-span-1">
                            <label className="text-primary-1 font-bold text-sm">Date</label>
                            <div className=" rounded-lg mt-2">{date}</div>
                        </div>
                        <div className="flex flex-col col-span-1 lg:col-span-1">
                            <label className="text-primary-1 font-bold text-sm">Time</label>
                            <div className=" rounded-lg mt-2">{trip.timeslot}</div>
                        </div>

                        <div className="flex flex-col col-span-1 lg:col-span-1 items-center">
                            <label className="text-primary-1 font-bold text-sm">Status</label>
                            <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${COLOR_CODES?.trip?.status[trip.status.toLowerCase()]}`}>
                                {trip.status}
                            </span>
                        </div>

                        <div className="flex flex-col col-span-1 lg:col-span-1">
                            <label className="text-primary-1 font-bold text-sm">Distance</label>
                            <div className=" rounded-lg mt-2">{/*trip.fare*/ Math.round(Math.random() * 100) + " km"}</div>
                        </div>

                        <div className="flex flex-col col-span-1 lg:col-span-1">
                            <label className="text-primary-1 font-bold text-sm">Fare</label>
                            <div className=" rounded-lg mt-2">{/*trip.fare*/ "$ " + Math.round(Math.random() * 1000)}</div>
                        </div>

                        <div className="flex flex-col col-span-1 lg:col-span-2">
                            <label className="text-primary-1 font-bold text-sm">Trailer</label>
                            <div className=" rounded-lg mt-2">
                                {
                                    (trip.trailer) ?
                                        `${trip?.trailer?.registrationNumber}`
                                    :
                                        "unassigned"
                                }
                            </div>
                        </div>

                        <div className="flex flex-col col-span-1 lg:col-span-2">
                            <label className="text-primary-1 font-bold text-sm">Vehicle</label>
                            <div className=" rounded-lg mt-2">
                                {
                                    (trip.vehicle) ?
                                        `${trip?.vehicle?.registrationNumber}-${trip?.vehicle?.car?.make}-${trip?.vehicle?.car?.model}`
                                    :
                                        "unassigned"
                                }
                            </div>
                        </div>

                        <div className="flex flex-col col-span-1 lg:col-span-2">
                            <label className="text-primary-1 font-bold text-sm">Driver</label>
                            <div className=" rounded-lg mt-2">{
                                (trip.driver) ?
                                    `${trip?.driver?.firstName} ${trip?.driver?.lastName}`
                                :
                                    "unassigned"
                                }
                            </div>
                        </div>
                       {
                            TripPermissions.isAuthorized("view",role) &&
                            <div className="flex  justify-center">
                                <Link className="flex justify-center my-2 bg-white items-center py-1 px-4 text-sm mx-2 border-2 border-primary-1 text-primary-1 hover:text-primary-2 hover:no-underline hover:border-neutral-6 rounded" to={`/trips/assign/${trip._id}`}>Assign</Link>
                                <Link className="flex justify-center my-2 bg-white items-center py-1 px-4 text-sm mx-2 border-2 border-primary-1 text-primary-1 hover:text-primary-2 hover:no-underline hover:border-neutral-6 rounded" to={`/trips/view/${trip._id}`}>View</Link>
                                <button
                                    className='p-1 text-primary-1'
                                    onClick={()=> setCollapsed(!collapsed)}
                                >
                                    {  collapsed ? <CancelIcon/> : <ExpandCircleDownIcon/>  }
                                </button>
                            </div>
                        }
                    
                    </div>

                </div>
                {   
                    collapsed &&
                    <div className='border-t border-t-emerald-600'>
                        <div className="grid grid-cols-5 w-full p-3 gap-2 text-sm text-gray-600">
                            <div className="col-span-2 font-bold">Address:</div>
                            <div className="col-span-1 font-bold">Point:</div>
                            <div className="col-span-1 font-bold">Status:</div>
                            <div className="col-span-1 font-bold">Quantity:</div>
                        </div>
                        
                        {
                            trip.path.map((point, index)=>{

                                return(
                                    <div className="grid grid-cols-5 w-full p-3 gap-2 text-gray-500 text-sm" key={index}>
                                        <div className="col-span-2">{point.address}</div>
                                        <div className="col-span-1">{point.pointType}</div>
                                        <div className="col-span-1">{point.status}</div>
                                        <div className="col-span-1">{point.capacity.length}</div>

                                       {/* { <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-2/5 gap-2">
                                            <div className='col-span-1'>{`weight: ${point.transitCapacity?.weight}  kg(s)`}</div>
                                            <div className='col-span-1'>{`length: ${point.transitCapacity?.leng}  m`}</div>
                                            <div className='col-span-1'>{`width: ${point.transitCapacity?.width}  m`}</div>
                                            <div className='col-span-1'>{`height: ${point.transitCapacity?.height}  m`}</div>
                                        </div>} */}
                                    </div>
                                )
                            })
                        }
                    </div>
                }                
                
            </div>
        )
}

export default TripComponent;