import React,{useEffect, useRef, createRef, useState} from "react";
import axios from "axios";
import context from "../../../context/index"
import {useParams,Link, useLocation, useNavigate} from 'react-router-dom'
import Select from "react-select";
import { getDates, TIMESLOTS } from "../../../util/date.util";
import { POINT_TYPE } from "../../../util/general.util";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import BookingSummary from "./BookingSummary";
import BookingDetails from "./BookingDetails";
import BookingDirections from "./BookingDirections";
import {
    GoogleMap,
    useJsApiLoader,
    Marker,
    Autocomplete,
    DirectionsRenderer,
} from "@react-google-maps/api";
import moment from "moment";

const libraries = ["places"];
const BOOKING_STAGES = [
    "details",
    "directions",
    "summary",
    "payment"
]

const EditBooking = () => {

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: context.GOOGLE_API_KEY,
        libraries
    });

    const {id} = useParams();
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTimeslot, setSelectedTimeslot] = useState('');
    const [directions, setDirections]= useState(null);
    const mapRef = useRef();
    const [points, setPoints] = useState([]);
    const [removedPoints, setRemovedPoints] = useState([]);
    const [cargoTypes, setCargoTypes] = useState([]);
    const [selectedCargoType, setSelectedCargoType] = useState([]);
    const [shared, setShared] = useState(false);
    const [products, setProducts] = useState([]);
    const [productCategories, setProductCategories] = useState([]);

    const location = useLocation();
    const stage = location?.state?.stage;
    const [bookingStage, setBookingStage] = useState(stage ? BOOKING_STAGES[stage] : BOOKING_STAGES[0]);
    const [fare, setFare] = useState(0);
    const [paidFare, setPaidFare] = useState(0);
    const [bookingState, setBookingState] = useState(null);

    const navigate = useNavigate();

    useEffect(()=>{
        fetchCargoTypes();
        fetchCompanies();
        getData();
    },[])

    const getData = async () =>{
        await fetchProductCategories();
        await fetchBooking();
    }

    const fetchCompanies = () =>{
        axios.get(`${context.SERVER_URL}/api/companies`)
        .then((response)=>{
            const companies = response.data.companies.map((company) => {
                return { label: company.name, value: company._id}
            });

            setCompanies(companies);
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    const fetchCargoTypes = () => {
        axios.get(`${context.SERVER_URL}/api/cargoTypes`)
        .then((response)=>{
            const _cargoTypes = response.data.map((cargoType)=>{
                return { label:  cargoType, value: cargoType }
            });
            setCargoTypes(_cargoTypes);

        }).catch((error)=>{
            console.log(error);
        })
    }

    const bookingChanged = () => {

        if (bookingState == null || points.length == 0) return false;

        let initialBookingStatePoints = bookingState.path.map((point, index, pointsArray)=>{
            let _pointCapacity = point.capacity.map((product)=>({
                description: product.description,
                leng: Number(product.leng),
                width: Number(product.width),
                height: Number(product.height),
                weight: Number(product.weight),
                category: product.category._id,
                packaging: product.packaging,
                quantity: Number(product.quantity)
            }));

            return({
                pointType: point.pointType,
                order: point.order,
                capacity: _pointCapacity,
                address: point.address,
                distance: point.distance,
                country: point.country
            })
        });

        let initialBookingState = {
            company: bookingState.company._id,
            timeslot: bookingState.timeslot,
            date:  moment(bookingState.date).format('YYYY-MM-DD'),
            path: initialBookingStatePoints,
            cargoType: bookingState.cargoType,
            shared: bookingState.shared,
        }

        let currentBookingStatePoints = points.map((point, index, pointsArray)=>{
            let _pointCapacity = point.capacity.map((product)=>({
                description: product.description,
                leng: Number(product.leng),
                width: Number(product.width),
                height: Number(product.height),
                weight: Number(product.weight),
                category: product.category._id,
                packaging: product.packaging,
                quantity: Number(product.quantity)
            }));

            return({
                pointType: point.type?.value,
                order: index,
                capacity: _pointCapacity,
                address: point.address,
                distance: point.distance,
                country: point.country
            })
        })

        let currentBookingState = {
            company: selectedCompany?.value,
            timeslot: selectedTimeslot?.value,
            date: selectedDate?.value,
            path: currentBookingStatePoints,
            cargoType: selectedCargoType.value,
            shared
        }

        return JSON.stringify(initialBookingState) != JSON.stringify(currentBookingState);
    }


    const fetchBooking = async () =>{
        try {
            let response1 = await axios.get(`${context.SERVER_URL}/api/productCategories`)
            let  _productCategories = response1.data.map((productCategory)=>({
                label: productCategory.name,
                value: productCategory._id
            }));

            let response = await axios.get(`${context.SERVER_URL}/api/productTemplates`);
            let _products = response.data;

            let res  = await axios.get(`${context.SERVER_URL}/api/bookings/${id}`);
            var booking = res.data;

            setBookingState(booking);

            setSelectedCompany({
                label: booking.company.name, value: booking.company._id
            });

            var date = moment(booking.date)

            setSelectedDate({
                label: date.format('D MMM YYYY (ddd)'),
                value: date.format('YYYY-MM-DD')
            });

            setSelectedTimeslot({
                label: booking.timeslot,
                value: booking.timeslot
            });

            setShared(booking.shared);

            setSelectedCargoType({
                label: booking.cargoType,
                value: booking.cargoType
            });

            setFare(booking.fare);

            setPaidFare(booking.paidFare);

            // adjust the incoming booking and fix up the products

            let _points = booking.path.map((point, index, pointsArray)=>{

                // adjust point capacity
                let _pointCapacity = point.capacity;

                // find in products if there is product that matches the product description
                _pointCapacity.map((capacityProduct)=>{

                    let foundProduct = _products.find((product) => product.description == capacityProduct.description )

                    if(foundProduct){
                        capacityProduct._id = foundProduct._id
                    }
                    else {
                        // add to the products list
                        let _category = _productCategories.find((category)=> category.value == capacityProduct.category);
                        _products.push({
                            category: { _id: _category.value, name: _category.label },
                            description: capacityProduct.description,
                            height: capacityProduct.height,
                            leng: capacityProduct.leng,
                            packaging: capacityProduct.packaging,
                            weight: capacityProduct.weight,
                            width: capacityProduct.width,
                            _id: capacityProduct._id
                        })
                    }
                });

                return({
                    identifier: (Math.random() * 1000),
                    _id: point._id,
                    type: (point.pointType == "location") ? { label: "pick", value: "location"} : { label: "drop", value: "destination"},
                    showAddStop: (index == 0) ? true : false,
                    showCancel: (index == 0 || index == (pointsArray.length-1) ) ? false : true,
                    disableTypeSelect: (index == 0 || index == (pointsArray.length-1) ) ? true : false,
                    disableCapacity: (index == (pointsArray.length-1) ) ? true : false,
                    placeholder: (index == 0) ? "Enter Location" : (index == (pointsArray.length-1)) ? "Enter Destination" : "Enter stop address",
                    capacity: _pointCapacity,
                    ref: createRef(),
                    address: point.address,
                    country: point.country,
                    distance: point.distance,
                    latitude: point.location.coordinates[1],
                    longitude: point.location.coordinates[0],
                })
            });
            setProducts(_products);
            calculateRoute(_points);
            setPoints(_points);
            setProductCategories(_productCategories);

        } catch (err) {
            console.log(err)
        }

    }


    const centerMap = async () =>{

        const pointsLatLng = await points.map(async(point)=>{
            return {
                lat: point.latitude,
                lng: point.longitude
            }
        });

        if (pointsLatLng[0].lat != null && pointsLatLng[pointsLatLng.length-1].lat != null)
        {
            var bounds = new window.google.maps.LatLngBounds();

            pointsLatLng.forEach((point)=>{
                if(point.lat != null && point.lng != null)
                {
                    bounds.extend(
                        {
                            lat: point.lat,
                            lng: point.lng
                        }
                    );
                }
            });

            mapRef.current?.setCenter(bounds.getCenter());
            mapRef.current?.fitBounds(bounds);
        }

    }

    const fetchProductTemplates = async () => {
    }

    const fetchProductCategories = async () =>{

    }
    const updateBooking = (proceedToPay) => {

        let path = [];
        let count = 0;
        [...points, ...removedPoints].forEach((point, index)=>{
            if (point.latitude != null)
            {
                let _capacity = point.capacity.map((product)=> {
                    if (product.hasOwnProperty("new") && product.new)
                        delete product._id;
                    return product;
                });
                path.push({
                    _id: point._id,
                    removed: point.removed,
                    pointType: point.type?.value,
                    order: count,
                    capacity: _capacity,
                    address: point.address,
                    country: point.country,
                    latitude: point.latitude,
                    longitude: point.longitude,
                    distance: (index == 0) ? 0 : directions.routes[0].legs[index - 1]?.distance.value/1000
                })

                count++;
            }
        });
        axios.put(`${context.SERVER_URL}/api/bookings/${id}`,
        {
            data: {
                company: selectedCompany?.value,
                timeslot: selectedTimeslot?.value,
                date: selectedDate?.value,
                distance: (directions == null) ? 0 : (directions.routes[0].legs.reduce((value, leg)=> value + leg.distance.value, 0)/1000).toFixed(2),
                path,
                cargoType: selectedCargoType.value,
                shared
            }
        })
        .then(async (response)=>{
            // console.log(response)
            await getData();
            if (proceedToPay)
            {
                navigate(`/payments/new/${bookingState._id}`)
            }
        })
        .catch((error)=>{
            console.log(error)
        })

    }
    const calculateRoute = (points) => {
        let pointsLatLng = points.map((point)=>{
            return {
                lat: point.latitude,
                lng: point.longitude
            }
        });

        const origin = pointsLatLng.shift();
        const destination = pointsLatLng.pop();
        let waypoints = [];
        pointsLatLng.forEach((point) => {
            if(point.lat != null)
            {
                waypoints.push({
                    location: point,
                    stopover: true
                })
            }
        });


        if (origin.lat != null && destination.lat != null)
        {
            const directionsService = new window.google.maps.DirectionsService();
            //const origin = { lat: pointsLatLng[0].lat, lng: pointsLatLng[0].lng };
            //const destination = { lat: pointsLatLng[pointsLatLng.length-1].lat, lng: pointsLatLng[pointsLatLng.length-1].lng };

            directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    waypoints,
                    travelMode: window.google.maps.TravelMode.DRIVING
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirections(result);
                    } else {
                        console.error(`error fetching directions ${result}`);
                    }
                }
            );
        }

    }

    const onAddStop = (pointType) => {

        let _points = [...points];

        let destination = _points.pop();

        let _newPoint = {
            identifier: (Math.random() * 1000),
            _id: null,
            type: ( pointType == "pick" ) ? { label: "pick", value: "location" } : { label: "drop", value: "destination" },
            showAddStop: false,
            showCancel: true,
            disableTypeSelect: false,
            disableCapacity: false,
            placeholder: "Enter Stop Address",
            capacity:[],
            ref: createRef(),
            address: null,
            latitude: null,
            longitude: null,
        };

        let _newPoints = [ ..._points, _newPoint, destination];

        setPoints(_newPoints);
    }

    const getFinalDestinationCapacity = (points) => {

        let destinationProducts = [...points].slice(0, -1).reduce((accumulated, point)=>{
            point.capacity.forEach((product) => {
                let key = product._id.toString();
                if (accumulated.hasOwnProperty(key))
                {
                    if (point?.type?.value == "location")
                    {
                        accumulated[key]["quantity"] = Number(accumulated[key]["quantity"]) +  Number(product.quantity);
                    }

                    if (point?.type?.value == "destination")
                    {
                        accumulated[key]["quantity"] = Number(accumulated[key]["quantity"]) - Number(product.quantity);
                    }

                }
                else
                {
                    accumulated[key] = { ...product };
                }
            });

            return  { ...accumulated };
        },{})

        let compiledProducts = [];

        for (const [key, product] of Object.entries(destinationProducts)) {

            if ( product.quantity != 0)
            {
                compiledProducts.push(product);
            }
        }

        let _points = [...points]

        _points[_points.length - 1]["capacity"] = compiledProducts;
        setPoints(_points);
    }

    const onSetPointCapacity = async (pointIndex, selectedProducts) => {

        let _points = [...points];
        let _capacity = selectedProducts.map(({product, quantity}) => ({
            ...product,
            quantity
        }));

        //check for new products and add them to the products list
        let newProducts = selectedProducts.filter(({product})=> !products.some((_product)=> _product._id == product._id));
        if (newProducts.length > 0)
        {
            setProducts([...products, ...newProducts.map(({product})=> product)]);
        }

        _points[pointIndex]["capacity"] =  _capacity;
        await setPoints(_points);

        getFinalDestinationCapacity(_points);
    }

    const cacheBookingPoducts = () => {
        // save booking products to cache
        localStorage.setItem("bookingProducts", JSON.stringify(products));
    }

    const onPointChanged = async (index, field, value) => {
        let _points = [...points];
        _points[index][field] = value;
        await setPoints(_points);
        getFinalDestinationCapacity(_points)
    }

    const removePoint = (identifier) =>{
        let _points = [];
        let _removedPoints = []

        points.forEach((point)=> {
            if(point.identifier != identifier)
            {
                _points.push(point);
            }

            if (point.identifier == identifier && point._id != null )
            {
                _removedPoints.push(point);
            }
        });

        // push into deleted
        setRemovedPoints((prevState) => ([
            ...prevState,
            ..._removedPoints
        ]));

        setPoints(_points);
        calculateRoute(_points);
    }

    if ( !isLoaded)
    {
        return(
            <div className="w-full h-full p-5">
                <h1 className="text-primary-1 font-bold">Edit Booking</h1>
                <div className="h-screen flex justify-center items-center w-full">
        <div role="status">
          <svg
            aria-hidden="true"
            class="w-28 h-28 mr-2 text-gray-200 animate-spin dark:text-primary-1 fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
            </div>
        )
    }

    return(
        <div className="w-full h-full p-5 overflow-y-auto">
            <div className="flex justify-between items-center">
            <h1 className="text-primary-1 font-bold">Edit Booking</h1>
            <div className='flex flex-row gap-3 text-gray-500 no-underline'>
                <Link
                    to = {"/bookings"}
                    className="bg-shade-1 rounded-md text-black hover:text-white hover:bg-shade-2 border border-black no-underline px-3 py-2"
                >bookings</Link>
                <Link
                    to = {`/bookings/view/${id}`}
                    className="bg-primary-1 rounded-md text-white px-3 py-2 no-underline hover:text-white"
                >view</Link>
            </div>
            </div>
            <div className='border border-gray-400 mt-1 mb-8'/>


            {
                (bookingStage == BOOKING_STAGES[0]) &&
                <BookingDetails
                    products={products}
                    companies={companies}
                    selectedCompany={selectedCompany}
                    setSelectedCompany={setSelectedCompany}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    selectedTimeslot={selectedTimeslot}
                    setSelectedTimeslot={setSelectedTimeslot}
                    cargoTypes={cargoTypes}
                    selectedCargoType={selectedCargoType}
                    setSelectedCargoType={setSelectedCargoType}
                    shared={shared}
                    setShared={setShared}
                    setBookingStage={setBookingStage}
                    cacheBookingPoducts={cacheBookingPoducts}
                    productCategories={productCategories}
                    fetchProductTemplates={fetchProductTemplates}
                />

            }
            {
                (bookingStage == BOOKING_STAGES[1]) &&
                <BookingDirections
                    directions={directions}
                    mapRef={mapRef}
                    points={points}
                    setPoints={setPoints}
                    onPointChanged={onPointChanged}
                    removePoint={removePoint}
                    onAddStop={onAddStop}
                    calculateRoute={calculateRoute}
                    setBookingStage={setBookingStage}
                    products = {products}
                    onSetPointCapacity = {onSetPointCapacity}
                    productCategories={productCategories}
                />
            }

            {
                (bookingStage == BOOKING_STAGES[2]) &&
                <BookingSummary
                    submitBooking={updateBooking}
                    setBookingStage={setBookingStage}
                    points={points}
                    selectedCompany={selectedCompany}
                    selectedDate={selectedDate}
                    selectedCargoType={selectedCargoType}
                    selectedTimeslot={selectedTimeslot}
                    shared={shared}
                    fare={fare}
                    paidFare = {paidFare}
                    directions={directions}
                    page = {"editBooking"}
                    bookingChanged = {bookingChanged}
                />
            }
        </div>
    )
}

export default EditBooking;
