import React, {useEffect, useState} from 'react';
import { TIMESLOTS, getDates } from '../../../util/date.util';

import BookingProducts from './BookingProducts';
import Select from 'react-select';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import context from '../../../context';

const BookingDetails = ({
    products,
    companies,
    selectedCompany,
    setSelectedCompany,
    selectedDate,
    setSelectedDate,
    selectedTimeslot,
    setSelectedTimeslot,
    selectedCargoType,
    setSelectedCargoType,
    cargoTypes,
    shared,
    setShared,
    setBookingStage,
    cacheBookingPoducts,
    fetchProductTemplates,
    productCategories
}) => {

    const [productsControl, setProductsControl] = useState([]);
    const [newProducts, setNewProducts] = useState([])

    useEffect(()=>{
        let _products = products.map((product)=>({
            ...product,
            category: { label: product.category.name, value: product.category._id },
            packaging: { label: product.packaging, value: product.packaging }
        }))
        setProductsControl(_products);
    },[products]);

    const addNewProduct = () => {
        let _newProducts =  [...newProducts];
        _newProducts.push({
            _id: null,
            description: '',
            leng: '',
            width: '',
            height: '',
            weight: '',
            category: { label: "", value: "" },
            packaging: { label: "Unit", value: "unit" }
        });
        setNewProducts(_newProducts);
    }

    const removeNewProduct = (index) => {
        let _newProducts = [...newProducts];
        _newProducts.splice(index,1);
        setNewProducts(_newProducts);
    }

    const onProductChanged = (index,field,value) => {
        let _productsControl = [...productsControl];
        _productsControl[index][field] = value;
        setProductsControl(_productsControl);
    }

    const onNewProductChanged = (index,field,value) => {
        let _newProducts = [...newProducts];
        _newProducts[index][field] = value;
        setNewProducts(_newProducts);
    }

    const deleteProduct = (product) => {

        const options = {
            title: 'Delete Product Template',
            message: 'Are you sure ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => {
                    axios.delete(`${context.SERVER_URL}/api/productTemplates/${product._id}`)
                    .then((response)=>{
                        fetchProductTemplates();
                    }).catch((error)=>{
                        console.log(error);
                    })
                }
            },
            {
                label: 'No',
                onClick: () =>{}
            }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypress: () => {},
            onKeypressEscape: () => {},
            overlayClassName: "overlay-custom-class-name"
        };
        confirmAlert(options);
    }

    const updateProduct = (product) => {
        axios.put(`${context.SERVER_URL}/api/productTemplates/${product._id}`,{
            data:{
                description: product.description,
                leng: product.leng,
                width: product.width,
                height: product.height,
                weight: product.weight,
                packaging: product.packaging.value,
                category: product.category.value,
            }
        })
        .then((response)=>{
            fetchProductTemplates();
        }).catch((error)=>{
            console.log(error);
        })
    }

    const createProductTemplates = async () => {
        const productTemplates = newProducts.map((product)=>({
            description: product.description,
            leng: product.leng,
            width: product.width,
            height: product.height,
            weight: product.weight,
            packaging: product.packaging.value,
            category: product.category.value,
        }));

        let response =  await  axios.post(`${context.SERVER_URL}/api/productTemplates`,{
            data:{
                productTemplates
            }
        });
        return response;
    }



    const onNext = async () => {
        // cache booking products
        //cacheBookingPoducts();

        try {

            if (newProducts.length > 0)
            {
                await createProductTemplates()
                fetchProductTemplates();
            }
            // go to next stage
            setBookingStage("directions");
        }
        catch (error)
        {
            console.log(error);
        }


    }

    return (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 w-full mb-10">
                <div className="col-span-1">
                    <label className="text-primary-1 font-bold">Company</label>
                    <Select
                        className="w-full mt-2"
                        placeholder="Select Company"
                        options={companies}
                        onChange={(value)=>setSelectedCompany(value)}
                        value={selectedCompany}
                    />
                </div>

                <div className="cols-span-1">
                    <label className="text-primary-1 font-bold">Date</label>
                    <Select
                        className="w-full mt-2"
                        placeholder="Select Date"
                        options={getDates()}
                        onChange={(value)=>setSelectedDate(value)}
                        value={selectedDate}
                    />
                </div>

                <div className="col-span-1">
                    <label className="text-primary-1 font-bold">Timeslot</label>
                    <Select
                        className="w-full mt-2"
                        placeholder="Select Timeslot"
                        options={TIMESLOTS}
                        onChange={(value)=>setSelectedTimeslot(value)}
                        value={selectedTimeslot}
                    />
                </div>

                <div className="col-span-1 border-l-2 border-primary-1 px-2">
                    <label className="text-primary-1 font-bold ">Share Space</label>
                    <div className=" flex flex-row w-full rounded-lg text-gray-500 py-1  mt-2">
                        <div className="mr-5">Less than Truck Load(LTL)</div>
                        <input
                            type="checkbox"
                            checked={shared}
                            onChange = { ()=>setShared((prev) => !prev ) }
                        />
                    </div>
                </div>
                <div className="col-span-1 md:col-span-2">
                    <label className="text-primary-1 font-bold">Cargo type</label>
                    <Select
                        className="w-full mt-2"
                        placeholder="Select cargo type"
                        options={cargoTypes}
                        onChange={(value)=>setSelectedCargoType(value)}
                        value={selectedCargoType}
                    />
                </div>

            </div>
            <BookingProducts
                productsControl={productsControl}
                onProductChanged={onProductChanged}
                updateProduct={updateProduct}
                deleteProduct={deleteProduct}

                addNewProduct={addNewProduct}
                removeNewProduct={removeNewProduct}
                newProducts={newProducts}
                onNewProductChanged={onNewProductChanged}

                productCategories={productCategories}

            />

            <div className='flex flex-row w-full items-center justify-end'>
                <button
                    className={`flex items-center justify-center px-4 py-2 ${ (newProducts.length == 0 && productsControl.length == 0) ? "border border-gray-500 bg-gray-200 text-gray-500" : "bg-primary-1  text-white px-3 py-2" } rounded-lg mt-5`}
                    onClick={()=>onNext()}
                    disabled={ newProducts.length == 0 && productsControl.length == 0}
                >
                    { (newProducts.length > 0) ? "Save and Continue" : "Next" }
                </button>
            </div>
        </div>
    )
}

export default BookingDetails;
