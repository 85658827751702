import React,{useEffect, useRef, useState, createRef } from "react";
import axios from "axios";
import context from "../../../context/index"

import { Link, useNavigate } from "react-router-dom";
import {
    Autocomplete,
    DirectionsRenderer,
    GoogleMap,
    Marker,
    useJsApiLoader,
} from "@react-google-maps/api";

import BookingDetails from "./BookingDetails";
import BookingDirections from "./BookingDirections";
import BookingSummary from "./BookingSummary";

const libraries = ["places"];


const BOOKING_STAGES = [
    "details",
    "directions",
    "summary"
]

const NewBooking = () => {

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: context.GOOGLE_API_KEY,
        libraries
    })

    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');

    const [selectedDate, setSelectedDate] = useState('');

    const [selectedTimeslot, setSelectedTimeslot] = useState('');

    const [directions, setDirections]= useState(null);

    const mapRef = useRef();

    const [cargoTypes, setCargoTypes] = useState([]);
    const [selectedCargoType, setSelectedCargoType] = useState([]);

    const [shared, setShared] = useState(false);

    const [products, setProducts] = useState([]);

    const [productCategories, setProductCategories] = useState([]);

    const [fare, setFare] = useState(0);
    const [paidFare, setPaidFare] = useState(0);

    const navigate = useNavigate();

    const cacheBookingPoducts = () => {
        // save booking products to cache
        localStorage.setItem("bookingProducts", JSON.stringify(products));
    }

    const getCachedBookingProducts = () => {
        // get cached booking products
        let _products = localStorage.getItem("bookingProducts");
        if (_products != null){
            _products = JSON.parse(_products);
            setProducts(_products);
        }
    }

    useEffect(()=>{
        fetchCargoTypes();
        fetchCompanies();
        //getCachedBookingProducts();
        fetchProductTemplates();
        fetchProductCategories();
    },[])

    const [bookingStage, setBookingStage] = useState(BOOKING_STAGES[0]);

    const centerMap = async () =>{

        const pointsLatLng = await points.map(async(point)=>{

            const { geometry: { location }} = await point.ref.current?.getPlace();

            return {
                lat: location?.lat(),
                lng: location?.lng()
            }
        });

        if (pointsLatLng[0].latitude != null && pointsLatLng[pointsLatLng.length-1].latitude != null)
        {
            var bounds = new window.google.maps.LatLngBounds();

            pointsLatLng.forEach((point)=>{
                if(point.latitude != null && point.longitude != null)
                {
                    bounds.extend(
                        {
                            lat: point.latitude,
                            lng: point.longitude
                        }
                    );
                }
            });

            mapRef.current?.setCenter(bounds.getCenter());
            mapRef.current?.fitBounds(bounds);
        }

    }

    const calculateRoute = (points) => {
        let pointsLatLng = points.map((point)=>{
            return {
                lat: point.latitude,
                lng: point.longitude
            }
        });

        const origin = pointsLatLng.shift();
        const destination = pointsLatLng.pop();
        let waypoints = [];
        pointsLatLng.forEach((point) => {
            if(point.lat != null)
            {
                waypoints.push({
                    location: point,
                    stopover: true
                })
            }
        });

        if (origin.lat != null && destination.lat != null)
        {
            const directionsService = new window.google.maps.DirectionsService();
            //const origin = { lat: pointsLatLng[0].lat, lng: pointsLatLng[0].lng };
            //const destination = { lat: pointsLatLng[pointsLatLng.length-1].lat, lng: pointsLatLng[pointsLatLng.length-1].lng };

            directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    waypoints,
                    travelMode: window.google.maps.TravelMode.DRIVING
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirections(result);
                    } else {
                    console.error(`error fetching directions ${result}`);
                    }
                }
            );
        }

    }

    const fetchCompanies = () =>{

        axios.get(`${context.SERVER_URL}/api/companies`)
        .then((response)=>{
            const companies = response.data.companies.map((company) => {
                return { label: company.name, value: company._id}
            });

            setCompanies(companies);
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const fetchCargoTypes = () => {
        axios.get(`${context.SERVER_URL}/api/cargoTypes`)
        .then((response)=>{
            const _cargoTypes = response.data.map((cargoType)=>{
                return { label:  cargoType, value: cargoType }
            });
            setCargoTypes(_cargoTypes);

        }).catch((error)=>{
            console.log(error);
        })
    }

    const fetchProductTemplates = () => {
        axios.get(`${context.SERVER_URL}/api/productTemplates`)
        .then((response)=>{
            const _productTemplates = response.data;
            setProducts(_productTemplates);
        }).catch((error)=>{
            console.log(error);
        })
    }

    const fetchProductCategories = () =>{
        axios.get(`${context.SERVER_URL}/api/productCategories`)
        .then((response)=>{
            const _productCategories = response.data.map((productCategory)=>({
                label: productCategory.name,
                value: productCategory._id
            }));
            setProductCategories(_productCategories);
        }).catch((error)=>{
            console.log(error);
        })
    }

    const submitBooking = () => {
        //generate path
        let path = [];
        let count = 0;
        points.forEach((point, index)=>{
            if (point.latitude != null)
            {
                let _pointCapacity = point.capacity.map((product)=>({
                    description: product.description,
                    leng: Number(product.leng),
                    width: Number(product.width),
                    height: Number(product.height),
                    weight: Number(product.weight),
                    category: product.category._id,
                    packaging: product.packaging,
                    quantity: Number(product.quantity)
                }));
                path.push({
                    pointType: point.type?.value,
                    order: count,
                    capacity: _pointCapacity,
                    address: point.address,
                    latitude: point.latitude,
                    longitude: point.longitude,
                    distance: (index == 0) ? 0 : directions.routes[0].legs[index - 1]?.distance.value/1000,
                    country: point.country
                })
                count++;
            }
        });

        axios.post(`${context.SERVER_URL}/api/bookings`,
        {
            data:
            {
                company: selectedCompany?.value,
                timeslot: selectedTimeslot?.value,
                date: selectedDate?.value,
                path,
                shared,
                cargoType: selectedCargoType?.value,
                distance: (directions == null) ? 0 : (directions.routes[0].legs.reduce((value, leg)=> value + leg.distance.value, 0)/1000).toFixed(2)
            }

        }).then((response)=>{
            //navigate to the edit page
            navigate(`/bookings/edit/${response.data._id}`,{
                state:{
                    stage: 2,
                }
            });

        }).catch((error)=>{
            console.log(error);

        })
    }

    const onSetPointCapacity = async (pointIndex, selectedProducts) => {

        let _points = [...points];
        let _capacity = selectedProducts.map(({product, quantity}) => ({
            ...product,
            quantity
        }));

        //check for new products and add them to the products list
        let newProducts = selectedProducts.filter(({product})=> !products.some((_product)=> _product._id == product._id));
        if (newProducts.length > 0)
        {
            setProducts([...products, ...newProducts.map(({product})=> product)]);
        }

        _points[pointIndex]["capacity"] =  _capacity;
        await setPoints(_points);

        getFinalDestinationCapacity();
    }

    const [points, setPoints] = useState([

        {
            identifier: (Math.random() * 10000),
            type: { label: "Pick", value: "location"},
            showAddStop: true,
            showCancel: false,
            disableTypeSelect: true,
            disableCapacity: false,
            placeholder: "Enter Location",
            capacity:[],
            autoComplete: null,
            ref: createRef(),
            address: null,
            latitude: null,
            longitude: null,
        },
        {
            identifier: (Math.random() * 10000),
            type: { label: "Drop", value: "destination" },
            disableTypeSelect: true,
            disableCapacity: true,
            showAddStop: false,
            showCancel: false,
            placeholder: "Enter Destination",
            capacity:[],
            ref: createRef(),
            address: null,
            latitude: null,
            longitude: null,
        }
    ]);

    const onAddStop = (pointType) => {

        let _points = [...points];

        let destination = _points.pop();

        let _newPoint = {
            identifier: (Math.random() * 1000),
            type: ( pointType == "pick" ) ? { label: "pick", value: "location" } : { label: "drop", value: "destination" },
            showAddStop: false,
            showCancel: true,
            disableTypeSelect: false,
            disableCapacity: false,
            placeholder: "Enter Stop Address",
            capacity:[],
            ref: createRef(),
            address: null,
            latitude: null,
            longitude: null,
        };

        let _newPoints = [ ..._points, _newPoint, destination];

        setPoints(_newPoints);
    }

    const onPointChanged = async (index,field,value) => {

        let _points = [...points];
        _points[index][field] = value;
        await setPoints(_points);
        getFinalDestinationCapacity()
    }

    const getFinalDestinationCapacity = () => {


        let destinationProducts = [...points].slice(0, -1).reduce((accumulated, point)=>{
            point.capacity.forEach((product) => {
                let key = product._id.toString();
                if (accumulated.hasOwnProperty(key))
                {
                    if (point?.type?.value == "location")
                    {
                        accumulated[key]["quantity"] = Number(accumulated[key]["quantity"]) +  Number(product.quantity);
                    }

                    if (point?.type?.value == "destination")
                    {
                        accumulated[key]["quantity"] = Number(accumulated[key]["quantity"]) - Number(product.quantity);
                    }

                }
                else
                {
                    accumulated[key] = { ...product };
                }
            });

            return  { ...accumulated };
        },{})

        let compiledProducts = [];

        for (const [key, product] of Object.entries(destinationProducts)) {

            if ( product.quantity != 0)
            {
                compiledProducts.push(product);
            }
        }

        let _points = [...points]

        _points[_points.length - 1]["capacity"] = compiledProducts;
        setPoints(_points);
    }

    const removePoint = (identifier) =>{
        let _points = points.filter((point)=> point.identifier != identifier );
        setPoints(_points);
        calculateRoute(_points);
    }

    if ( !isLoaded)
    {
        return(
            <div className="w-full h-full p-5">
                <h1 className="text-primary-1 font-bold">New Booking...</h1>
                <div className="h-screen flex justify-center items-center w-full">
        <div role="status">
          <svg
            aria-hidden="true"
            class="w-28 h-28 mr-2 text-gray-200 animate-spin dark:text-primary-1 fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
            </div>
        )
    }

    return(
        <div className="w-full h-full p-5 overflow-y-auto">
            <div className="flex justify-between items-center">
            <h1 className="text-primary-1 font-bold">New Booking</h1>
            <div className='text-gray-500'>
                <Link
                    to = {"/bookings"}
                    className = " text-primary-1 font-bold border border-primary-1 px-4 py-2 rounded-md hover:bg-primary-1 hover:text-white hover:no-underline transition duration-300 ease-in-out"
                >Bookings</Link>
            </div>
            </div>
            <div className='border border-gray-400 mt-1 mb-8'/>

            {
                (bookingStage == BOOKING_STAGES[0]) &&
                <BookingDetails
                    products={products}
                    companies={companies}
                    selectedCompany={selectedCompany}
                    setSelectedCompany={setSelectedCompany}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    selectedTimeslot={selectedTimeslot}
                    setSelectedTimeslot={setSelectedTimeslot}
                    cargoTypes={cargoTypes}
                    selectedCargoType={selectedCargoType}
                    setSelectedCargoType={setSelectedCargoType}
                    shared={shared}
                    setShared={setShared}
                    setBookingStage={setBookingStage}
                    cacheBookingPoducts={cacheBookingPoducts}
                    productCategories={productCategories}
                    fetchProductTemplates={fetchProductTemplates}
                />

            }
            {
                (bookingStage == BOOKING_STAGES[1]) &&
                <BookingDirections
                    directions={directions}
                    mapRef={mapRef}
                    points={points}
                    setPoints={setPoints}
                    onPointChanged={onPointChanged}
                    removePoint={removePoint}
                    onAddStop={onAddStop}
                    calculateRoute={calculateRoute}
                    setBookingStage={setBookingStage}
                    products = {products}
                    onSetPointCapacity = {onSetPointCapacity}
                    productCategories={productCategories}
                />
            }

            {
                (bookingStage == BOOKING_STAGES[2]) &&
                <BookingSummary
                    submitBooking = {submitBooking}
                    setBookingStage={setBookingStage}
                    points={points}
                    selectedCompany={selectedCompany}
                    selectedDate = {selectedDate}
                    selectedCargoType = {selectedCargoType}
                    selectedTimeslot = {selectedTimeslot}
                    shared={shared}
                    fare={fare}
                    paidFare={paidFare}
                    directions={directions}
                    page={"newBooking"}
                />
            }
        </div>
    )

}

export default NewBooking;
